// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
/* eslint-disable */
import Bubble from '~/packages/spa-ec/src/displayComponents/Bubble/Bubble.theme-dammsugarpasar.js';
import ContactUsPage from '~/packages/spa-ec/src/routes/ContactUsPage/ContactUsPage.theme-dammsugarpasar.js';
import MyPages from '~/packages/spa-ec/src/components/MyPages/MyPages.theme-dammsugarpasar.js';
import RestoreCartPanel from '~/packages/spa-ec/src/components/RestoreCart/RestoreCartPanel.theme-dammsugarpasar.js';

const overrides = {
  Bubble,
  ContactUsPage,
  MyPages,
  RestoreCartPanel,
};
export default overrides;

// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
